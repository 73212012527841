const destinations = [
  { name: "Cyprus", slug: "cyprus" },
  { name: "Tunisia", slug: "tunisia" },
  { name: "Saint Lucia", slug: "st-luicia" },
  { name: "Croatia", slug: "croatia" },
]

const links = [
  { key: "home", link: "/", name: "Home" },
  { key: "cyprus", link: "/destination/cyprus", name: "Cyprus" },
  { key: "tunisia", link: "/destination/tunisia", name: "Tunisia" },
  { key: "st-luicia", link: "/destination/st-luicia", name: "Saint Lucia" },
  { key: "croatia", link: "/destination/croatia", name: "Croatia" },
  { key: "reservation", link: "/reservation", name: "Reservation" },
]

module.exports = { destinations, links }
