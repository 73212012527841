import { Link } from "gatsby"
import * as React from "react"
import { links } from "../data"

export default function Header({ selected }) {
  return (
    <ul>
      {links.map(({ link, name, key }) => (
        <li key={key} style={{ fontWeight: selected === key ? 700 : 400 }}>
          <Link to={link}>{name}</Link>
        </li>
      ))}
    </ul>
  )
}
