import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ lang, title }) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const { title: siteName } = siteMetadata

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={siteName ? `%s | ${siteName}` : null}
    />
  )
}

export default Seo
